.maintenanceCTA {
	position: relative;
	margin: 0 auto 93px;
	max-width: @wrapper_width;
	background: @primary_gradient;
	color: @light_color;
	border-radius: 15px;
	overflow: hidden;
	
	@media all and (max-width: (@wrapper_width + 60px)) {
		margin: 0 30px 80px;
	}
	
	&__inner {
		@media all and (min-width: 1001px) {
			display: flex;
			flex-flow: row nowrap;
			align-items: flex-end;
		}
	}
	
	&__content {
		padding: 35px 32px 32px;

		@media all and (min-width: 1001px) {
			flex: 1 1 60%;
		}
		
		@media all and (max-width: 1000px) {
			position: relative;
			z-index: 3;
			width: 100%;
		}
		
		&Title {
			.rems(22);
			line-height: 25px;
			color: @light_color;
			margin: 0 0 23px;
		}
		
		&Desc {
			margin: 0 0 23px;
		}
		
		&Buttons {
			display: flex;
			flex-flow: row-reverse wrap;
			justify-content: flex-end;
			align-items: center;
			gap: 11px;
			
			.button {
				margin: 0;
				padding: 12px 14px 10px 18px;
				
				@media @mobile {
					width: 100%;
				}
				
				&:not(.buttonOutlined) {
					background: @light_color;
					color: @primary_color;
					transition: opacity .3s ease;
					
					&:hover {
						opacity: 0.7;
					}
				}
				
				&.buttonOutlined {
					border: 1px solid @light_color;
					color: @light_color;
					
					&:hover {
						background: @light_color;
						color: @primary_color;
					}
				}
			}
		}
	}
	
	&__image {
		text-align: center;
		padding: 0 20px;

		@media all and (min-width: 1001px) {
			flex: 1 0 300px;
		}

		@media all and (max-width: 1000px) {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0.1;
		}
		
		&Wrap {
			height: 100%;
			display: flex;
			flex-flow: column nowrap;
			justify-content: flex-end;
			
			> img {
				max-height: 100%;
				width: auto;

				@media all and (max-width: 1000px) {
					width: 100%;
					height: 100%;
					object-fit: contain;
					object-position: bottom center;
				}
			}
		}
	}
}
