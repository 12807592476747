@hero_breakpoint: 800px;

.heroWrap {
    .heroSection {
        background: #fff;

        .heroInner {
            position: relative;
            clear: both;
        }

        @media all and (min-width: @wrapper_width + 1px) {
            .heroInner {
                min-height: 500px;
                padding: 90px 0 100px 100px;
            }
        }

        @media all and (max-width: @wrapper_width) and (min-width: @hero_breakpoint + 1px) {
            padding: 0 0 50px;

            .heroInner {
                padding: 50px 0;
            }
        }

        @media all and (max-width: @hero_breakpoint) {
            padding: 0 0 50px;

            .heroInner {
                display: flex;
                flex-flow: column-reverse nowrap;
            }
        }

        .heroContent {
            position: relative;

            @media all and (min-width: @hero_breakpoint + 1px) {
                max-width: 632px;
            }

            h1 {
                .rems(45);
                line-height: 55px;
                font-weight: 400;
                margin: 0 0 50px;

                strong {
                    font-weight: 700;
                }
            }

            ul {
                display: flex;
                flex-flow: column nowrap;
                align-items: flex-start;
                gap: 10px;

                &:before, &:after {
                    display: none;
                }

                li {
                    display: flex;
                    flex-flow: row nowrap;
                    gap: 8px;
                    background: @tertiary_gradient;
                    padding: 10px 12px;
                    border-radius: 50px;
                    .rems(12);
                    line-height: 12px;
                    font-weight: 400;

                    svg {
                        display: inline-block;
                        height: 12px;
                        width: 12px;
                    }
                }
            }

            @media all and (max-width: 1000px) and (min-width: @hero_breakpoint + 1px) {
                max-width: 470px;

                h1 {
                    .rems(35);
                    line-height: 45px;
                    margin: 0 0 30px;
                }
            }

            @media all and (max-width: @hero_breakpoint) {
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                padding: 50px 0;
                margin: -166px 0 0;
                z-index: 3;
            }

            @media all and (max-width: 600px) {
                h1 {
                    .rems(33);
                    line-height: 43.244px;
                    margin: 0 0 40px;
                }

                ul {
                    li {
                        align-items: center;
                        .rems(14);
                        line-height: 16px;
                        background: unset;
                        padding: 0;

                        svg {
                            height: 16px;
                            width: 16px;
                            flex: 0 0 16px;
                        }
                    }
                }
            }
        }

        .heroImage {

            img {
                height: auto;
            }

            @media all and (min-width: @hero_breakpoint + 1px) {
                position: absolute;
                top: -40px;
                right: 0;
                z-index: 100;
            }

            @media all and (max-width: @wrapper_width) and (min-width: @hero_breakpoint + 1px) {
                top: 45%;
                transform: translateY(-50%);

                img {
                    max-width: 530px;
                }
            }

            @media all and (min-width: @wrapper_width + 1px) {
                img {
                    max-width: 660px;
                }
            }

            @media all and (max-width: @wrapper_width) and (min-width: 1051px) {
                img {
                    max-width: 530px;
                }
            }

            @media all and (max-width: 1050px) and (min-width: @hero_breakpoint + 1px) {
                right: -40px;

                img {
                    max-width: 400px;
                }
            }

            @media all and (max-width: @hero_breakpoint) {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -30px;
                    right: -30px;
                    bottom: 0;
                    background: rgba(255, 255, 255, 0.44);
                    z-index: 2;
                }

                img {
                    max-width: unset;
                    width: auto;
                    margin: 0 30px 0 0;
                }
            }

            @media all and (max-width: @hero_breakpoint) and (min-width: @mobile_width + 1) {
                img {
                    max-width: 440px;
                }
            }

            @media @mobile {
                img {
                    max-width: 370px;
                }
            }
        }
    }

    .featuredCategories {
        position: relative;
        margin: -130px 0 0;
		min-height: 330px;

        @media all and (max-width: @hero_breakpoint) {
            margin: -80px 0 0;
            z-index: 3;
        }
    }
}

.landingBanner {
    position: relative;
    overflow: hidden;

    .bannerSection {
        background: #fff;

        .bannerInner {
            position: relative;
            clear: both;
        }

        @media all and (min-width: @wrapper_width + 1px) {
            .bannerInner {
                min-height: 500px;
                padding: 90px 0 100px 100px;
            }
        }

        @media all and (max-width: @wrapper_width) and (min-width: @hero_breakpoint + 1px) {
            padding: 0 0 50px;

            .bannerInner {
                padding: 50px 0;
            }
        }

        @media all and (max-width: @hero_breakpoint) {
            padding: 0 0 50px;

            .bannerInner {
                display: flex;
                flex-flow: column-reverse nowrap;
            }
        }

        .bannerContent {
            position: relative;
            z-index: 100;

            @media all and (min-width: @hero_breakpoint + 1px) {
                max-width: 632px;
            }

            h1 {
                .rems(35);
                line-height: 55px;
                font-weight: 400;
                margin: 0 0 25px;

                strong {
                    font-weight: 700;
                }
            }

            .bannerText {
                margin: 0 0 35px;
                .rems(12);
                line-height: 12px;
            }

            .buttonWrap {
                display: flex;
                flex-flow: row wrap;
                gap: 10px;

                .button {
                    margin: 0;
                    padding: 16px 15px;
                }
				
				.consultationComplete{
					position: relative;
					display: inline-block;
					background: @success_color;
					padding: 16px 15px;
					text-align: center;
					font-family: @primary_font;
					color: #fff;
					font-weight: 400;
					.rems(12);
					line-height: 12px;
					transition: all 200ms linear;
					border-radius: 50px;
					z-index: 1;
					overflow: hidden;
					letter-spacing: 0.24px;
					
					@media @mobile {
						.rems(14);
						line-height: 1.4117em;
					}
				}
            }

            @media all and (max-width: 1136px) and (min-width: @hero_breakpoint + 1px) {
                max-width: 470px;

                h1 {
                    .rems(35);
                    line-height: 45px;
                    margin: 0 0 30px;
                }
            }

            @media all and (max-width: @hero_breakpoint) {
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                padding: 10px 0;
                z-index: 3;

                .bannerText {
                    margin: 0 0 30px;
                    .rems(13.5);
                    line-height: 20px;
                }
            }

            @media all and (max-width: 600px) {
                h1 {
                    .rems(33);
                    line-height: 43.244px;
                    margin: 0 0 30px;
                }
            }
        }

        .bannerImage {

            img {
                height: auto;
            }

            svg {
                aspect-ratio: 487 / 484;
            }

            @media all and (min-width: @hero_breakpoint + 1px) {
                position: absolute;
                top: 0;
                width: 487px;

                img {
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    transform: translateX(-50%);
                }
            }

            //@media all and (max-width: @wrapper_width) and (min-width: @hero_breakpoint + 1px) {
            //    img {
            //        max-width: 530px;
            //        max-height: 420px;
            //    }
            //}

            @media all and (min-width: @wrapper_width + 1px) {
                right: 0;

                img {
                    max-width: 487px;
                    max-height: 500px;
                }
            }

            @media all and (max-width: @wrapper_width) and (min-width: 1101px) {
                right: -50px;

                img {
                    max-width: 487px;
                    max-height: 420px;
                }
            }

            @media all and (max-width: 1110px) and (min-width: @hero_breakpoint + 1px) {
                right: -100px;

                img {
                    max-width: 400px;
                    max-height: 100%;
                }
            }

            @media all and (max-width: @hero_breakpoint) {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 2;

                img {
                    opacity: 0.7;
                }
            }

            @media all and (max-width: @hero_breakpoint) and (min-width: @mobile_width + 1) {
                img {
                    max-width: 100%;
                    max-height: 401px;
                }
            }

            @media @mobile {
                img {
                    max-width: 100%;
                    max-height: 301px;
                }
            }
        }

        .mobileBackground {
            @media all and (min-width: @hero_breakpoint + 1px) {
                display: none;
            }

            @media all and (max-width: @hero_breakpoint) {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                display: block;
                opacity: 0.3;
                z-index: 1;

                svg {
                    aspect-ratio: 487 / 484;
                }
            }

            @media all and (max-width: @hero_breakpoint) and (min-width: @mobile_width + 1) {
                svg {
                    width: 658px;
                }
            }

            @media @mobile {
                svg {
                    width: 558px;
                }
            }
        }
    }

    .bannerBorder {
        position: absolute;
        bottom: -15px;
        left: 0;
        right: 0;
        width: 100%;
    }
}
