.followButtons {

    float: none;
    clear: both;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;


    ul {

        &:before, &:after {
            display: none !important;
        }

        display: flex;
        flex-direction: row;
        width: ~"calc(100% + 8px)";
        margin: 0 -4px;

    }

    li {
        float: none;
        clear: both;
        width: auto !important;
        padding: 0 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            background: @dark_color !important;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 34px;
            height: 34px;
            .rems(18);
            line-height: 18px;
            text-align: center;
            border-radius: 50%;
            background: fade(@highlight_color, 0%);
            transition: background 200ms linear, color 200ms linear;
            text-decoration: none !important;
			
			@media @mobile {
				width: 42px;
				height: 42px;
			}



            i {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 21px;
                position: relative;
                color: #fff;

				@media @mobile {
					height: 28px;
				}

                svg {
                    width: auto;
                    height: 21px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                }
            }

            &:hover {
                color: #fff;
                background: fade(@dark_color,80%) !important;
                transition: background 200ms linear, color 200ms linear;
            }

            &:active {
                color: #fff;
                background: fade(@dark_color,90%) !important;
                transition: background 200ms linear, color 200ms linear;
            }

        }
    }
}
