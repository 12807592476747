.maintenancePage {

	.innerPageContent {

		max-width: 980px;
		margin: 0 auto;

	}

	.latestBlogPosts {

		margin: 0;
		padding: 80px 0 0;

	}
	
	.maintenancePlans {
		margin: 20px 0 60px;
		
		@media @mobile {
			margin: 10px 0 30px;
		}
		
		&__title {
			margin: 0 0 31px;
			
			h2 {
				.rems(21);
				font-weight: normal;
				line-height: 25px;
				letter-spacing: 0.01px;
				text-align: center;
				margin: 0;
			}
		}
		
		&__inner {
			display: flex;
			flex-flow: row wrap;
			gap: 31px;
		}
		
		&__card {
			position: relative;
			display: flex;
			flex-flow: column nowrap;
			background: #ffffff;
			padding: 41px 30px 51px;
			border-radius: 25px;
			overflow: hidden;
			flex-grow: 1;
			
			@media @mobile {
				padding: 35px 30px 20px;
			}
			
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: var(--planColour);
				opacity: 0.38;
			}
			
			> div {
				position: relative;
				z-index: 5;
			}
			
			&Title {
				.rems(22);
				font-weight: 400;
				line-height: 25px;
				text-transform: uppercase;
				margin: 0 0 14px;
			}
			
			&Price {
				.rems(22);
				font-weight: 600;
				line-height: 25px;
				margin: 0 0 28px;
				
				@media @mobile {
					.rems(18);
				}
				
				&--main {
					.rems(42);
					line-height: 1;
					margin: 0 8px 0 0;
					
					@media @mobile {
						.rems(38);
					}
				}
				
				&--original {
					color: @primary_color;
					text-decoration: line-through;
					margin: 0 8px 0 0;
				}

				&Prefix {
					.rems(13);
					font-weight: 600;
					line-height: 1.1;
					text-transform: uppercase;
					margin: 0 0 10px;
				}
			}
			
			&Content {
				.rems(12);
				font-weight: normal;
				line-height: 14px;
				letter-spacing: 0.24px;
				margin: 0 0 24px;
				flex-grow: 1;
				
				ul {
					margin-left: 30px;
					padding-bottom: 16px;

					li {
						padding: 0;
						list-style: none;
						position: relative;
						margin: 0 0 28px;
						
						@media @mobile {
							margin: 0 0 18px;
						}

						&:last-child {
							margin: 0;
						}

						&:before {
							content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z" fill="white"/></svg>');
							background: var(--planColour);
							text-align: center;
							position: absolute;
							left: -30px;
							top: -6px;
							width: 12px;
							height: 12px;
							display: block;
							padding: 4px 5px 5px;
							border-radius: 50%;
						}
					}
				}
			}
			
			&Footer {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				align-items: center;
				gap: 15px;
				
				.button {
					margin: 0;
					display: flex;
					flex-flow: row nowrap;
					align-items: center;
					justify-content: space-between;
					background: var(--planColour);
					.rems(12);
					font-weight: normal;
					line-height: 14px;
					letter-spacing: 0.24px;
					width: 193px;

					@media all and (min-width: (@mobile_width + 1px)) {
						display: flex;
						flex-flow: row nowrap;
						align-items: center;
						justify-content: space-between;
						width: 193px;
					}

					@media @mobile {
						width: 100%;
					}
				}
				
				.moreInfoLink {
					color: @text_color;
					.rems(12);
					font-weight: normal;
					line-height: 14px;
					letter-spacing: 0.24px;
					
					@media @mobile {
						display: inline-block;
						padding: 15px;
					}
					
					.svgIcon {
						width: auto;
						height: auto;
						display: inline-block;
						text-align: center;
						transform: rotate(90deg);
						
						svg {
							position: unset;
							transform: unset;
							left: unset;
							top: unset;
							height: 10px;
							width: 9px;
						}
					}
				}
			}
		}
	}
	
	.maintenanceInfo {
		margin: 50px 0 80px;
		
		@media @mobile {
			margin: 30px 0 40px;
		}
		
		&__inner {
			display: flex;
			flex-flow: column nowrap;
			gap: 40px;
			max-width: 980px;
			margin: 0 auto;
		}
		
		&__info {
			position: relative;
			background: #ffffff;
			padding: 30px 0 0;
			border-radius: 25px;
			overflow: hidden;
			
			@media @mobile {
				padding: 20px 0 0;
			}

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 30px;
				background: var(--planColour);
				opacity: 0.38;
				
				@media @mobile {
					height: 20px;
				}
			}
			
			&Inner {
				padding: 38px 54px 60px;
				
				@media @mobile {
					padding: 25px
				}
			}

			&Title {
				.rems(22);
				font-weight: 400;
				line-height: 25px;
				text-transform: uppercase;
				margin: 0 0 14px;
			}

			&Price {
				.rems(26);
				font-weight: 600;
				line-height: 25px;
				margin: 0 0 28px;

				@media @mobile {
					.rems(20);
				}

				&--main {
					.rems(56);
					line-height: 1;
					margin: 0 8px 0 0;

					@media @mobile {
						.rems(42);
					}
				}

				&--original {
					color: @primary_color;
					text-decoration: line-through;
					margin: 0 8px 0 0;
				}

				&Prefix {
					.rems(13);
					font-weight: 600;
					line-height: 1.1;
					text-transform: uppercase;
					margin: 0 0 10px;
				}
			}

			&Content {
				.rems(14);
				font-weight: normal;
				line-height: 22px;
				letter-spacing: 0.24px;
				margin: 0 0 24px;
				
				h1, h2, h3 {
					.rems(25);
					font-weight: normal;
					line-height: 1;
					letter-spacing: 0.01px;
					margin: 0 0 23px;
				}
				
				h4 {
					.rems(18);
					font-weight: normal;
					line-height: 25px;
					margin: 25px 0 23px;
					
				}

				h5 {
					.rems(16);
					font-weight: bold;
					line-height: 22px;
					margin: 25px 0 23px;
				}

				h6 {
					.rems(14);
					font-weight: bold;
					line-height: 22px;
					margin: 25px 0 23px;
				}
				
				p {
					font-size: inherit;
					font-weight: inherit;
					line-height: inherit;
					letter-spacing: inherit;
				}

				ul {
					margin-left: 40px;
					padding-bottom: 16px;

					li {
						padding: 0;
						list-style: none;
						position: relative;
						max-width: 683px;
						margin: 0 0 34px;

						&:last-child {
							margin: 0;
						}

						&:before {
							content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z" fill="white"/></svg>');
							background: var(--planColour);
							text-align: center;
							position: absolute;
							left: -30px;
							top: -2px;
							width: 12px;
							height: 12px;
							display: block;
							padding: 4px 5px 5px;
							border-radius: 50%;
						}
					}
				}
			}

			&Footer {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				align-items: center;

				.button {
					margin: 0;
					background: var(--planColour);
					.rems(12);
					font-weight: normal;
					line-height: 14px;
					letter-spacing: 0.24px;
					
					@media all and (min-width: (@mobile_width + 1px)) {
						display: flex;
						flex-flow: row nowrap;
						align-items: center;
						justify-content: space-between;
						width: 193px;
					}
					
					@media @mobile {
						width: 100%;
					}
				}
			}
		}
	}

	.dropdownListWrap {

		h3 {
			text-align: center;
		}

		> ul.faqs.contentFaq {
			margin: 32px 0;
			padding: 36px 50px;
			background: #F7F0EE;
			border-radius: 25px;

			@media @mobile {
				padding: 25px;
			}
			
			li {
				> h4 {
					.rems(18);
					line-height: 25px;
				}
				
				.answer {
					max-width: 90%;
					.rems(14);
					line-height: 22px;
				}
			}			
		}
	}
	
	.pageContent {
		text-align: center;
	}
	
	.ctaButtonBanner {
		padding: 24px 0 37px;
		display: block;
		width: 100%;

		.button {
			margin: 0 auto;
			background: @primary_gradient;
			.rems(12);
			font-weight: normal;
			line-height: 14px;
			letter-spacing: 0.24px;
			
			@media all and (min-width: (@mobile_width + 1px)) {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				justify-content: space-between;
				width: 193px;
			}
			
			@media @mobile {
				width: 100%;
				.rems(16);
			}
		}
	}
}


