
@content_sidebar_width: 400px;
@content_sidebar_padding: 96px;


/*
 * Content + Sidebar
 */

.contentPageWrap {
    .wrapper();
    float: none;
    clear: both;
    padding-top: 96px;
    padding-bottom: 96px;

    @media @sidebar_mobile {
        padding-top: @content_padding_top_flyout;
        padding-bottom: @content_padding_bottom_flyout;
    }

    @media @mobile {
        padding-top: 48px;
        padding-bottom: @content_padding_bottom_mobile;
    }

	&.contentSidebarPage {

		padding-top: 24px;
		padding-bottom: 80px;

		@media @sidebar_mobile {
			padding-top: 0;
			padding-bottom: 64px;
		}

		@media @mobile {
			padding-top: 0;
			padding-bottom: 40px;
		}

	}

	.generalContentImage {
		img {
			width: 100%;
			height: auto;
		}
	}

    .contentWrap, .contentSidebar {
        float: none;
        clear: both;
    }

    .innerPageContent {
        display: flex;
        flex-wrap: wrap;
    }

    .contentWrap {
        width: 100%;
        float: none;
        clear: both;
        margin: 0 auto;


        .contentTitleWrap {
            h1, h2 {
                .rems(62);
                line-height: 71px;
                font-weight: 300;
                margin-bottom: 32px;

                @media @sidebar_mobile {
                    .rems(32);
                    line-height: 40px;
                    margin-bottom: 24px;
                }

                @media @mobile {
                    .rems(24);
                    line-height: 32px;
                    margin-bottom: 16px;
                }
            }
        }

        .pageContent {

            table {

                @media @tablet {
                    font-size: 13px;
                    table-layout: auto;
                }

                @media @mobile {
                    font-size: 13px;
                    table-layout: auto;
                }

                tbody {

                    tr {
                        td {

                            &:nth-child(2n-1) {
                                border-left: none;
                                border-right: 1px solid @border_color;
                                padding-right: 20px;
                            }

                            &:nth-child(2n) {
                                border-left: 1px solid @border_color;
                                border-right: none;
                            }

                            a {
                                text-decoration: none;
                            }

                            @media @tablet {
                                text-align: center;
                                overflow: hidden;
                                max-width: 25%;
                                width: 25%;
                                word-wrap: break-word;
                            }

                            @media @mobile {
                                text-align: center;
                                overflow: hidden;
                                max-width: 25%;
                                width: 25%;
                                word-wrap: break-word;
                            }
                        }
                    }
                }
            }

            p:last-of-type {
                margin-bottom: 24px;
            }

        }

        ul.faqs.contentFaq {
            margin: 32px 0;

            padding: 25px;
            background: #F7F0EE;
            border-radius: 25px;
        }

        .blockContainer {

            margin-top: 24px;

            .contentSmallBlockContainer {

                margin-bottom: 24px;

                h3 {
                    text-align: left;

                    @media @tablet {
                        text-align: center;
                    }

                    @media @mobile {
                        text-align: center;
                    }
                }

                p {
                    text-align: left;
                    color: #999999;
                	margin-bottom: 0;

                	@media @tablet {
                    	text-align: center;
                	}

                	@media @mobile {
                    	text-align: center;
                	}

                    &:last-child {
                        color: #00A98D;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .contentSidebar {
        width: @content_sidebar_width;
        order: 2;

        @media @sidebar_mobile {
            width: 100%;
        }
    }

    .contentSidebar + .contentWrap {

        width: ~"calc(100% - @{content_sidebar_width})";
        padding: 0 @content_sidebar_padding 0 0;
        order: 1;

        @media all and (max-width: 1200px) {
            padding: 0 32px 0 0;
        }

        @media @sidebar_mobile {
            padding: 0;
            margin-bottom: 32px;
            width: 100%;
        }

    }


    .contentImage {
        margin-bottom: 40px;
        margin-top: 40px;

        &.firstImage {
            margin-top: 0;
        }
    }



    .dropdownListWrap {

        & > h3 {
            .rems(24);
            line-height: 32px;
            margin-bottom: 24px;
        }

        .contentFaq {
            margin-top: 0 !important;

            li {

				.answer {
					display: none;
				}

            }
        }
    }
	
	
}

.splitContent {
    width: ~"calc(100% + 64px)";
    margin: 0 -32px;
    display: flex;
    flex-wrap: wrap;

    @media all and (max-width: 1200px) {
        width: ~"calc(100% + 32px)";
        margin: 0 -16px;
    }


    .contentLeft {
        width: ~"calc(100% - 460px)";
        padding: 0 32px;

        @media all and (max-width: 1100px) {
            padding: 0 16px;
        }

        @media @sidebar_mobile {
            width: 100%;
        }
    }

    .contentRight {
        width: 460px;
        padding: 0 32px;

        @media all and (max-width: 1100px) {
            padding: 0 16px;
        }

        @media @sidebar_mobile {
            width: 100%;
            margin-top: 32px;
        }
    }
}

#browse {
    padding-top: 0;
    padding-bottom: @content_padding_bottom;

    @media @sidebar_mobile {
        padding-bottom: @content_padding_bottom_flyout;
    }

    @media @mobile {
        padding-bottom: @content_padding_bottom_mobile;
    }
}


.stickyParent {

    height: 100%;
    background: transparent;


}

/*
* Lists as boxes with checkmarks
*/
.wysiwyg.boxLists {


    ul {

        position: relative;
        float: none;
        clear: both;

        &:before, &:after {
            display: none;
        }

        &:after {
            content: "";
            width: 100%;
            height: 2px;
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            margin-top: -1px;
            background: @primary_color;
        }

        padding: 0 !important;
        width: ~"calc(100% + 40px)";
        margin: 8px -20px 16px -20px !important;
        display: flex !important;
        flex-wrap: wrap;
        text-align: left;

        @media all and (max-width: 1200px) {
            margin: 0 -20px 24px -20px !important;
        }

        @media all and (max-width: 900px) {
            text-align: left;
        }

        @media all and (max-width: 550px) {
            width: ~"calc(100% + 24px)";
            margin: 8px -12px 16px -12px !important;
        }

        @media all and (max-width: 440px) {
            width: 100%;
            margin: 8px 0 16px 0 !important;
        }

        li {
            list-style: none !important;
            margin: 0 20px;
            padding: 19px 0 19px 28px !important;
            width: ~"calc(50% - 40px)";
            .rems(14);
            line-height: 18px;
            border-bottom: 1px solid @border_color;
            position: relative;
            font-weight: 500;

            .darkBg & {
                border-color: rgba(255,255,255,0.2);
            }

            @media all and (max-width: 550px) {
                margin: 0 12px;
                padding: 19px 16px 19px 28px !important;
                width: ~"calc(50% - 24px)";
            }

            @media all and (max-width: 440px) {
                width: 100%;
                margin: 0;
            }

            &:before {
                .fa(f00c);
                position: absolute;
                left: 5px;
                top: 21px;
                .rems(14);
                line-height: 14px;
                width: 14px;
                height: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: @secondary_color;
                font-weight: 300;

            }
        }



    }

    &.whiteBg {
        ul {
            &:after {
                background: #fff;
            }
        }
    }

    &.lightBg {
        ul {
            &:after {
                background: @light_color;
            }
        }
    }
}
