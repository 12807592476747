//Breakpoints
@footer_meta_mobile_breakpoint: 1120px;

//Colours
@footer_border_color: rgba(0,0,0,0.032);
@footer_bg_color: @dark_gradient;


//Groups
@columns: 4;
@newsletter_group_width: 429px;

//Newsletter
@newsletter_padding: 12px 56px 12px 24px;
@newsletter_button_padding: 0;

//Newsletter font
@newsletter_form_font_size: 16;
@newsletter_form_line_height: 24px;

/*
 * Footer
 */
.footerWrap {
    position: relative;
    float: none;
    clear: both;
    width: 100%;
    background: @footer_bg_color;

    .hasCookieNotice & {
        padding-bottom: 40px;

        @media @flyout {
            padding-bottom: 56px;
        }

        @media @mobile {
            padding-bottom: 88px;
        }
    }

    &.mobileMenuAttached {

        @media @flyout {
            padding-bottom: 96px;
        }

        @media @mobile {
            padding-bottom: 96px;
        }
    }

    @media @mobile {
        padding: 44px 0 30px;

        .wrapper {
            padding: 0 41px;
        }
    }

    &.hasProgressBar {
        @media @flyout {
            padding-bottom: 90px;
        }
    }

    .footer {
        float: none;
        clear: both;
        position: relative;
        color: #fff;
        width: 100%;
        padding: 0;
        .rems(16);
        line-height: 24px;

        .lightBg & {
            color: @text_color;
        }

        @media @mobile {
            .rems(14);
            line-height: 24px;
        }

        @media all and (max-width: 450px) {
            .rems(13);
            line-height: 20px;
        }

        &:before, &:after {
            display: none;
        }

    }

    .stickyFooter & {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .contactSocial {

        display: flex;
        padding-top: 16px;

        @media @mobile{
            display: none;
        }

    }

    .mobileSocial {
        background: transparent;
        padding: 24px 0;
        display: none;
        justify-content: center;
        border-top: 1px solid @footer_border_color;


        @media @mobile{
            display: flex;
        }

        .socialTitle {
            text-align: center;
            margin-bottom: 8px;
        }

        .followButtons {
            width: auto;
        }

    }

    .menuGroup, .mobileSocial {

        .followButtons {

           padding: 24px 0 0;
        }

    }

    .contactFooterWrap {
        flex: 3 1 50%;

        .newsletterFormWrap {
            margin: 0 0 32px;
        }

        .contactInfo {
            margin: 0 0 20px;

            a {
                color: #fff;

                &:hover {
                    color: @secondary_color;
                }
            }

            .divider {
                color: @primary_color;
            }
        }

        .enquire {
            display: flex;
            flex-flow: row wrap;
            gap: 11.5px;

            .button {
                flex-shrink: 0;
                margin: 0;

                @media all and (min-width: @mobile_width + 1) {
                    .rems(15.5);
                    line-height: 17.986px;
                    padding: 14px 15px 13px;

                    .svgIcon svg {
                        height: 15.5px;
                        width: auto;
                    }
                }
            }

            .followButtons {
                width: unset;

                ul {
                    width: unset;
                }

                li {
                    a {
                        background: transparent!important;
						transition: opacity .3s ease;

						&:hover {
							opacity: 0.8;
						}
                    }
                }
            }
        }

        @media all and (min-width: @mobile_width + 1) {
            &.mobileOnly, .mobileOnly {
                display: none!important;
            }
        }

        @media @mobile {
            &.desktopOnly, .desktopOnly {
                display: none!important;
            }

            &.mobileOnly {
                padding: 33px 0 0;
            }

            .enquire {
                align-items: center;

                .button {
                    .rems(12);
                    line-height: 14px;
                    padding: 11px 16px 10px;

                    .svgIcon svg {
                        height: 10px;
                        width: auto;
                    }
                }
            }
        }
    }

    .newsletterFormWrap {

        padding-top: 0;

        .successMessage {
            .rems(20);
            line-height: 32px;
            color: @secondary_color;
            font-weight: 600;
            margin-bottom: 32px;
            float: none;
            clear: both;

            .message {
                .rems(14);
                line-height: 24px;
                font-weight: normal;
                color: #fff;
            }
        }

        form {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 18px;

            &:before, &:after {
                display: none;
            }

            .row {

                float: none;
                clear: both;
                width: 100%;
                margin: 0;

                @media all and (min-width: @mobile_width + 1) {
                    flex: 0 1 304px;
                }

                label {
                    color: #fff;
                    .rems(12);
                    line-height: 14px;
                    font-weight: 400;
                    margin: 0 0 15px;
                }

                .validationMessage {
                    padding: 8px 0;
                    .rems(14);
                    line-height: 24px;
                    font-weight: 600;

                    @media @mobile {
                        text-align: center;
                    }
                }

                .inputWrap {

                    &:after {
                        top: 26px;
                    }

                    input {
                        position: relative;
                        z-index: 1;
                        .rems(14);
                        line-height: 22px;
                        font-weight: 400;
                        padding: 15px 16px;
                        background: #fff;
                        .placeholder(fade(@text_color, 50%));
                        border: none;
                        color: @text_color;
                        border-radius: 10px;

                        &:focus {
                            border: none;
                        }
                    }

                    input::placeholder {
                        opacity: 1;
                    }

                    &.withButton {
                        display: flex;
                        flex-flow: row nowrap;
                        z-index: 1;

                        input {
                            padding: 15.5px 19.5px 14.5px;
                            border-radius: 10px 0 0 10px;
                        }

                        .button {
                            display: flex;
                            flex-flow: row nowrap;
                            align-items: center;
                            .rems(12);
                            gap: 10px;
                            background: @secondary_color_3;
                            border-color: @secondary_color_3;
                            border-radius: 0 10px 10px 0;
                            padding: 15.5px 15.5px 14px;
                            margin: 0;
                            flex-shrink: 0;

                            &:hover {
                                background: @dark_secondary;
                                border-color: @dark_secondary;
                            }

                            i {
                                padding: 0;
                                margin: 0;
                                .rems(20);
                                line-height: 24px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding-bottom: 4px;

                                &:before {
                                    font-size: inherit;
                                    line-height: inherit;
                                }
                            }
                        }
                    }
                }
            }

            .button {
                background: @secondary_gradient;
                margin: 20px 0 0;

                &:hover, &:focus, &:active {
                    color: #fff;
                    background: darken(@secondary_color_2, 8%)!important;
                    border-color: darken(@secondary_color_2, 8%)!important;
                    transition: all 200ms linear;
                }
            }
        }
    }

    &.lightBg {

        background: #fff;
        color: @text_color;
        border-top: none;

        .footer {
            a {
                color: @text_color;

                &:hover, &:active {
                    color: @highlight_color;
                }


            }
        }
    }


	ul.paymentIcons {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin: 0 -10px;
        gap: 10px;

		&:before, &:after {
			display: none;
		}

        @media @mobile {
            width: 100%;
            margin: 0;
        }

		& > li {

			padding: 6px;
            background: #fff;
            border-radius: 6px;
            border: 1px solid #d9d9d9;

			& > picture {
				width: auto;

				img, svg {
					width: auto;
					height: 100%;
					max-height: 26px;
					min-height: 16px;

					//@media @mobile {
					//	filter: grayscale(1);
					//}
				}
			}
		}
	}
}


.footerTop {
	display: flex;
	padding: 75px 0 0;

	@media @mobile {
		display: none;
	}

	.footerTopLeft {
		width: 50%;
		display: flex;
		align-items: center;
	}

	.footerTopRight {
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	ul.paymentIcons {

		justify-content: flex-end;

		@media @mobile {
			display: none;
		}
	}

	.logo {
		width: 250px;
		margin: 0;
	}

}

.footerAddress, .mobileAddress {

	& > span {
		display: block;
		width: 100%;

		@media @mobile {
			display: inline-block;
			width: auto;

			&:not(.noSeparator) {
				padding-right: 4px;

				&:after {
					content: ",";
				}
			}

			&.noSeparator {
				&:after {
					display: none;
				}
			}
		}

		&.spaced {
			margin-top: 10px;

			@media @mobile {
				display: block;
			}
		}
	}
}

/*
 * Footer menu groups
 */
.menuGroupsWrap {
    float: none;
    clear: both;
    width: ~"calc(100% + 24px)";
    margin: 0 -12px;
    padding: 75px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    @media all and (max-width: 900px) {
        flex-wrap: wrap;
    }

    @media @mobile {
        width: 100%;
        margin: 0;
		justify-content: center;
        padding: 0;
    }

    &:before, &:after {
        display: none;
    }

    .menuGroup {
        float: none;
        clear: both;
        text-align: left;
		justify-content: space-between;
        .rems(12);
        line-height: 14px;
        font-weight: 400;
        color: #fff;
        padding: 0 12px;
        flex: 1 1 auto;
        width: auto;
        margin-bottom: -6px;
        letter-spacing: 0.24px;


        .lightBg & {
            color: @text_color;
        }

        @media all and (max-width: 900px) and (min-width: @mobile_width + 1) {
            border: none !important;
            flex: 0 0 50%;
            width: 50%;
        }

		@media @mobile {
			width: 100%;
			flex: 0 0 100%;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            padding: 31px 0 32px;
		}



        &.newsletterGroup {
            flex: 1 0 @newsletter_group_width;
            width: @newsletter_group_width;

            @media all and (max-width: 1000px) {
                flex: 0 0 auto;
                width: 100%;
                border-top: 1px solid @footer_border_color;
                padding-top: 32px;
                margin-top: 32px;
            }

            @media @mobile {
                padding-top: 24px;
                margin-top: 0;
            }

            .menuGroupTitle {
                @media all and (max-width: 1000px) {
                    width: 50%;
                    margin: 0 auto 16px auto;
                    text-align: center;
                }

                @media @flyout {
                    margin-bottom: 16px;
                }

                @media all and (max-width: 700px) {
                    width: 100%;
                }
            }

            .newsletterFormWrap {
                @media all and (max-width: 1000px) {
                    width: 50%;
                    margin: 0 auto;
                    padding-top: 0;
                }

                @media all and (max-width: 700px) {
                    width: 100%;
                }
            }

            .footerLogoArea {
                @media all and (max-width: 1000px) {
                    width: 50%;
                    margin: 0 auto;
                }

                @media all and (max-width: 700px) {
                    width: 100%;
                }

                .trustLogos {
                    @media all and (max-width: 1000px) {
                        justify-content: center;
                        padding-top: 8px;
                    }
                }
            }
        }


        .menuGroupTitle {
            margin: 0 0 35px;
            color: #fff;
            .rems(18);
            line-height: 25px;
            font-weight: 400;
            letter-spacing: unset;
			text-transform: unset;
			position: relative;


            @media @mobile {
                margin: 0 !important;

				&:hover, &:active {
					color: @highlight_color;
				}
            }


			&:after {
				display: none;
			}

            .lightBg & {
                color: @text_color;
            }

            &.addressTitle {
                display: none;
            }

        }


        .dropdownWrap {

            @media @mobile {
				display: none;
                padding: 28px 0 0;
            }

            &.mobileMenuDrop {
                position: relative;
                top: initial;
                left: initial;
                background: transparent;
                overflow: initial;
                display: block;
            }

            & > ul {

                margin-top: 0;

                li, mtc-menu {
                    width: 100%;
                    margin: 0 0 24px;
					display: block;

                    @media @mobile {
                        margin: 0 0 27px;
                    }

                    &:last-child {
                        margin: 0;
                    }

                    a {
                        text-decoration: none;
                        .rems(12);
                        line-height: 14px;
                        color: #fff;
                        padding: 0;
                        transition: color 200ms linear;
                        display: inline-block;

                        &:hover, &:active {
                            color: @highlight_color;
                            transition: color 200ms linear;
                        }

                        .menuIcon.noDropdown {
                            display: none;
                        }

                    }

                    .lightBg & {

                        a {
                            color: @text_color;

                            &:hover, &:active {
                                color: @highlight_color;
                            }

                        }
                    }
                }
            }
        }

        &:not(.newsletterGroup) {
            @media @mobile {
                max-width: none;
                width: 100%;
                flex: 0 0 100%;
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                padding: 31px 0 32px;
            }

            .menuGroupTitle {

                @media @mobile {
                    .rems(18);
                    line-height: 25px;
                    margin: 0;
                    border-bottom: none;
                    cursor: pointer;
                    position: relative;

                    &:after {
                        display: inline-block;
                        .fa(f107);
                        transform: rotate(0deg);
                        transition: transform 200ms linear;
                        .rems(18);
                        line-height: 25px;
                        margin: 0 0 0 14px;
                    }

                    &.open {

                        &:after {
                            transform: rotate(-180deg);
                            transition: transform 200ms linear;
                        }
                    }
                }
            }
        }

        .dropdownWrap, .footerContactDetails {


            .addressBox {

                background: #fff;
                color: @text_color;
                padding: 32px;
                box-shadow: 0 10px 10px rgba(0,0,0,0.08);
                border-radius: @border_radius;

                .addressBoxTitle {
                    color: @text_color;
                    margin: 0 0 16px;
                    font-weight: bold;

                    @media @flyout {
                        margin-bottom: 16px !important;
                    }
                }

                .addressBoxWrapper {

                    @media @flyout {
                        .rems(14);
                        line-height: 20px;
                    }

                    p {

                        .rems(14) !important;
                        line-height: 17px;
                        font-weight: 600;
                        margin-bottom: 16px;

                        @media all and (max-width: 400px) {
                            .rems(12) !important;
                            line-height: 20px !important;
                        }

                        &:last-of-type {
                            margin: 0;
                        }

                        a {
                            font-weight: 600;
                            margin-top: 6px;
                            display: block;
                            width: 100%;
                            .rems(17);
                            line-height: 24px;

                            @media all and (max-width: 400px) {
                                .rems(14);
                                line-height: 20px;
                            }
                        }
                    }

                    a {
                        .rems(16);
                        line-height: 24px;

                        @media @flyout {
                            .rems(14) !important;
                            line-height: 24px !important;
                        }

                        @media all and (max-width: 400px) {
                            .rems(12) !important;
                            line-height: 20px !important;
                        }
                    }

                    p > a {

                        color: @secondary_color;

                        &:hover {
                            color: darken(@secondary_color, 8%);
                        }

                        &:active {
                            color: darken(@secondary_color, 4%);
                        }
                    }
                }
            }

        }

        .footerContactDetails {

            @media @flyout {
                margin-left: 24px;
                margin-right: 24px;
                margin-top: 16px;

                .addressBoxTitle {
                    padding: 0 !important;
                }

                .addressBoxWrapper {
                    &, p {
                        .rems(14);
                        line-height: 24px;
                    }
                }
            }

            .addressBox {
                @media @mobile {
                    padding: 24px !important;
                }
            }
        }
    }

}

/*
* Footer Logos Banner
*/
.trustLogoBar {
    float: none;
    clear: both;
    width: 100%;
    background: #fff;
    padding: 24px 0;

    .innerBar {

        display: flex;
        justify-content: center;

        .footerLogoArea {

            .trustLogos {

                width: ~"calc(100% + 32px)";
                margin: 0 -16px -16px;
                justify-content: center;

                li {
                    padding: 0 16px;
                }

            }
        }
    }


}

/*
* Credits and Copyright
*/
.footerMeta {
    float: none;
    clear: both;
    width: 100%;
    //background: @footer_bg_color;
    text-align: center;
    .rems(14);
    line-height: 24px;
    color: #fff;
    font-weight: normal;



    a {
        color: #fff;
        opacity: 1;
        transition: opacity 200ms linear, color 200ms linear;

        &:hover {
            color: #fff;
            opacity: 0.8;
            transition: opacity 200ms linear, color 200ms linear;
        }

        &.mtcLink {
            &:hover {
                color: @mtc_blue !important;
                opacity: 1 !important;
                transition: opacity 200ms linear, color 200ms linear;
            }
        }
    }

    &.lightBg {

        background: #fff;
        color: @text_color;
		border-top: none;

        a {
            color: @text_color;

            &:hover, &:active {
                color: @highlight_color;
            }

        }
    }

    .innerFooterBar {

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
        margin: 0;
		padding: 25px 0 74px;
		letter-spacing: 0.1em;


        @media all and (max-width: @wrapper_width) {
            width: 100%;
            margin: 0;
        }

        @media all and (max-width: @footer_meta_mobile_breakpoint) {
            width: 100%;
            margin: 0;
        }

		@media @flyout {
			padding: 25px 0 50px;
			flex-wrap: wrap;
		}

		@media @mobile{
			padding: 33px 0 0;
		}

        .credits {
            float: none;
            clear: both;
            text-align: center;
            padding: 0 @gutter_width 0 0;
            width: 40%;
            flex: 0 0 40%;

            > div {
                .rems(10);
                line-height: 15px;
                font-weight: 400;
                letter-spacing: 0.2px;
            }

            @media all and (max-width: @wrapper_width) {
                padding: 0 @gutter_width_mobile 0 0;
            }

            @media all and (max-width: @footer_meta_mobile_breakpoint) {
                width: 100%;
                flex: 0 0 100%;
                //justify-content: center;
                order: 2;
                padding: 0 0 12px;
                text-align: left;
            }

            span:not(.dash) {
                display: inline-block;
                width: 100%;
                text-align: left;

                @media all and (max-width: @footer_meta_mobile_breakpoint) {
                    text-align: left;
                }
            }

        }

        .paymentsBar {
            display: flex;
            align-items: center;
            justify-content: flex-start;
			gap: 20px;
            flex: 1 1 30%;
            width: 30%;
            //padding: 0 @gutter_width;

            @media all and (max-width: @wrapper_width) {
                padding: 0 @gutter_width_mobile;
            }

            @media all and (max-width: @footer_meta_mobile_breakpoint) {
                width: 100%;
                margin-bottom: 16px;
                order: 1;
                padding: 0;
            }

			@media @mobile{
				display: flex;
			}

        }

		.credits, .copyrightBlock {

			&, a {
				.rems(12);
				line-height: 1.2em;
				font-weight: 400;
				text-transform: unset;
			}

		}

        .copyrightBlock {
            float: none;
            clear: both;
            //display: flex;
            //align-items: center;
            //justify-content: flex-end;
            text-align: right;
            margin-left: auto;
            padding: 0 0 0 @gutter_width;
            width: 30%;
            flex: 1 1 30%;

            @media all and (max-width: @wrapper_width) {
                padding: 0 0 0 @gutter_width_mobile;
            }

            @media all and (max-width: @footer_meta_mobile_breakpoint) {
                text-align: left;
                width: 100%;
                flex: 0 0 100%;
                order: 3;
                padding: 0;
            }

            .innerCopyright, .companyCopyright {
                width: 100%;

                .rems(10);
                line-height: 15px;
                font-weight: 700;
                text-transform: unset;

                a {
                    .rems(10);
                    line-height: 12px;
                    font-weight: 700;
                    text-transform: unset;
                }
            }
        }



    }


    br {
        display: none;

        @media @mobile {
            display: block;
        }
    }
    .dash {
        @media @mobile {
            display: none !important;
        }
    }

}

.fixedWhatsAppBtn {
	position: fixed;
	display: inline-block;
	right: 10px;
	bottom: 10px;
	z-index: 9999;
	
	&__link {
		position: relative;
		display: flex;
		flex-flow: row-reverse;
		align-items: center;
		width: auto;
		background: @primary_color;
		cursor: pointer;
		text-align: center;
		outline: none;
		font-family: area-normal, 'Helvetica Neue', Helvetica, Arial, sans-serif;
		color: #fff;
		font-weight: 400;
		border-radius: 50px;
		text-decoration: none;
		box-shadow: none;
		overflow: hidden;
		z-index: 1;
		opacity: 1;
		
		transition: opacity .3s ease;
		
		&:hover {
			color: #fff;
			opacity: 0.7;
		}
	}
	
	&__icon {
		display: block;
		position: relative;
		height: 46px;
		width: 46px;
		
		svg {
			display: inline;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			height: 20px;
			width: auto;
		}
	}
	
	&__label {
		display: block;
		font-weight: 400;
		.rems(15.5);
		line-height: 17.986px;
		letter-spacing: 0.24px;
		text-transform: unset;
		padding: 6px 0 0 20px;
		
		@media all and (max-width: 800px) {
			display: none;
		}
	}

	&--whatsapp {
		.fixedWhatsAppBtn {
			&__link {
				background: #25D366;
			}
			
			&__icon {
				svg {
					height: 26px;
				}
			}
		}
	}
}
